import type {
    ContextGetter,
    FlowType,
    SalutationType,
    PropertyTypesType,
    MeasurementSystemType,
    BusinessDivisionType,
    LeadIntentionsType,
} from './contextTypes';

import { getStringFromField } from './utils';

export const getPageContext: ContextGetter<{
    type?: string;
    country_code?: string; // ISO 3166-1 alpha-2 Country code
    page_language?: string;
    template_type?: string;
    isHQPage: boolean;
    page_tag?: string,
    first_published_at?: string
    schemaVersion?: string;
}> = ({
    type,
    isHQPage,
    country_code,
    page_language,
    template_type,
    page_tag,
    first_published_at,
    schemaVersion = '1-0-4',
}) => ({
    schema: `iglu:com.engelvoelkers/page_context/jsonschema/${schemaVersion}`,
    data: {
        page_type: getStringFromField(type),
        is_hq: isHQPage,
        alpha2_country_code: getStringFromField(country_code),
        page_language,
        template_type,
        country_code: getStringFromField(country_code),
        page_tag,
        first_published_at
    },
});

export const getDivisionContext: ContextGetter<{
    name: BusinessDivisionType;
    schemaVersion?: string;
}> = ({ name, schemaVersion = '1-0-0' }) => ({
    schema: `iglu:com.engelvoelkers/business_division_context/jsonschema/${schemaVersion}`,
    data: {
        name,
    },
});

export const getUserContext: ContextGetter<{
    salutation?: SalutationType;
    email: string;
    userType?: string;
    phoneNumber?: string;
    ldapId?: string;
    contactPreferences?: string[] | string;
    viewingPreferences?: string[];
    contactSelected?: boolean;
    viewingSelected?: boolean;
    expose?: boolean;
    otherInformation?: boolean;
    schemaVersion?: string;
}> = ({
    salutation,
    email,
    userType,
    phoneNumber,
    ldapId,
    contactPreferences,
    viewingPreferences,
    contactSelected,
    viewingSelected,
    otherInformation,
    expose,
    schemaVersion = '1-0-8',
}) => ({
    schema: `iglu:com.engelvoelkers/user_context/jsonschema/${schemaVersion}`,
    data: {
        salutation,
        email,
        user_type: userType,
        phone_number: phoneNumber,
        ldap_id: ldapId,
        contact_preferences: contactPreferences,
        viewing_preferences: viewingPreferences,
        contact_selected: contactSelected,
        viewing_selected: viewingSelected,
        expose,
        other_information: otherInformation,
    },
});

export const getLeadContext: ContextGetter<{
    flow: FlowType;
    intention?: LeadIntentionsType;
    jobTitle?: string;
    schemaVersion?: string;
}> = ({ flow, intention, jobTitle, schemaVersion = '1-0-1' }) => ({
    schema: `iglu:com.engelvoelkers/lead_context/jsonschema/${schemaVersion}`,
    data: {
        flow,
        intention,
        job_title: jobTitle,
    },
});

export const getSortContext: ContextGetter<{
    sortOrder?: string;
    sortField?: string;
    schemaVersion?: string;
}> = ({ sortField, sortOrder, schemaVersion = '1-0-0' }) => ({
    schema: `iglu:com.engelvoelkers/sort_context/jsonschema/${schemaVersion}`,
    data: {
        sort_field: sortField,
        sort_order: sortOrder,
    },
});

export type ValuationContextDataSource = {
    name: string;
    responseTime?: string;
    responseStatus?: string;
    error?: string;
    metadata?: unknown;
};

export const getValuationContext: ContextGetter<{
    price?: string | number;
    minPrice?: string | number;
    maxPrice?: string | number;
    minRentPrice?: string | number;
    maxRentPrice?: string | number;
    currency?: string;
    confidenceScore?: string | number;
    success?: boolean;
    totalResponseTime?: string | number;
    dataSource?: ValuationContextDataSource[];
    schemaVersion?: string;
}> = ({
    price,
    minPrice,
    maxPrice,
    minRentPrice,
    maxRentPrice,
    confidenceScore,
    currency,
    success,
    dataSource,
    totalResponseTime,
    schemaVersion = '1-0-2',
}) => ({
    schema: `iglu:com.engelvoelkers/valuation_context/jsonschema/${schemaVersion}`,
    data: {
        price: getStringFromField(price),
        price_min: getStringFromField(minPrice),
        price_max: getStringFromField(maxPrice),
        rent_price_min: getStringFromField(minRentPrice),
        rent_price_max: getStringFromField(maxRentPrice),
        currency: getStringFromField(currency),
        confidence_score: getStringFromField(confidenceScore),
        totalResponseTime: getStringFromField(totalResponseTime),
        success,
        dataSource,
    },
});

export const getPropertyContext: ContextGetter<{
    propertyId?: string;
    profileId?: string;
    algoliaObjectId?: string;
    bffObjectId?: string;
    type?: PropertyTypesType;
    subType?: string;
    constructionYear?: string | number;
    currency?: string;
    price?: string | number;
    measurementSystem?: MeasurementSystemType;
    measurementUnit?: string;
    yachtLength?: string | number;
    yachtWidth?: string | number;
    yachtSpeed?: string | number;
    yachtCategory?: string | number;
    yachtNumberOfGuests?: string | number;
    livingArea?: string | number;
    plotArea?: string | number;
    floor?: string | number;
    totalFloor?: string | number;
    rooms?: string | number;
    bathrooms?: string | number;
    bedrooms?: string | number;
    propertyCondition?: string;
    hasKitchen?: boolean;
    hasAlarmSystem?: boolean;
    hasGarden?: boolean;
    hasTerrace?: boolean;
    hasBalcony?: boolean;
    hasElevator?: boolean;
    hasCellar?: boolean;
    hasAirconditioning?: boolean;
    hasPatio?: boolean;
    hasSeaview?: boolean;
    hasTennisYard?: boolean;
    hasParking?: boolean;
    hasBoxroom?: boolean;
    hasPool?: boolean;
    hasGuestToilet?: boolean;
    isWaterfront?: boolean;
    isElegant?: boolean;
    hasSquashCourt?: boolean;
    hasClubHouse?: boolean;
    hasGolfCourse?: boolean;
    hasPlayground?: boolean;
    isWheelchairAccessible?: boolean;
    hasBuiltinWardrobe?: boolean;
    isFurnished?: boolean;
    isSeniorOriented?: boolean;
    hasOpenView?: boolean;
    hasGreenView?: boolean;
    hasMountainView?: boolean;
    hasJacuzzi?: boolean;
    hasSauna?: boolean;
    hasGym?: boolean;
    hasAttic?: boolean;
    hasGarage?: boolean;
    hasLeaseAgreement?: boolean;
    schemaVersion?: string;
}> = ({
    propertyId,
    profileId,
    algoliaObjectId,
    bffObjectId,
    type,
    subType,
    price,
    currency,
    measurementSystem,
    measurementUnit,
    yachtCategory,
    yachtLength,
    yachtWidth,
    yachtSpeed,
    yachtNumberOfGuests,
    constructionYear,
    livingArea,
    plotArea,
    floor,
    totalFloor,
    rooms,
    bathrooms,
    bedrooms,
    propertyCondition,
    hasKitchen,
    hasAlarmSystem,
    hasGarden,
    hasTerrace,
    hasBalcony,
    hasElevator,
    hasCellar,
    hasAirconditioning,
    hasPatio,
    hasSeaview,
    hasTennisYard,
    hasParking,
    hasBoxroom,
    hasPool,
    hasGuestToilet,
    isWaterfront,
    isElegant,
    hasSquashCourt,
    hasClubHouse,
    hasGolfCourse,
    hasPlayground,
    isWheelchairAccessible,
    hasBuiltinWardrobe,
    isFurnished,
    isSeniorOriented,
    hasOpenView,
    hasGreenView,
    hasMountainView,
    hasJacuzzi,
    hasSauna,
    hasGym,
    hasAttic,
    hasGarage,
    hasLeaseAgreement,
    schemaVersion = '1-0-10',
}) => ({
    schema: `iglu:com.engelvoelkers/property_context/jsonschema/${schemaVersion}`,
    data: {
        property_id: getStringFromField(propertyId),
        profile_id: getStringFromField(profileId),
        algolia_object_id: getStringFromField(algoliaObjectId),
        bff_object_id: getStringFromField(bffObjectId),
        property_type: getStringFromField(type),
        property_sub_type: getStringFromField(subType),
        year_of_construction: getStringFromField(constructionYear),
        living_area: getStringFromField(livingArea),
        plot_area: getStringFromField(plotArea),
        floor: getStringFromField(floor),
        total_floors: getStringFromField(totalFloor),
        rooms: getStringFromField(rooms),
        bedrooms: getStringFromField(bedrooms),
        bathrooms: getStringFromField(bathrooms),
        property_condition: getStringFromField(propertyCondition),
        price: getStringFromField(price),
        currency: getStringFromField(currency),
        measurement_system: getStringFromField(measurementSystem),
        measurement_unit: getStringFromField(measurementUnit),
        yacht_category: getStringFromField(yachtCategory),
        yacht_length: getStringFromField(yachtLength),
        yacht_width: getStringFromField(yachtWidth),
        yacht_speed: getStringFromField(yachtSpeed),
        yacht_number_of_guests: getStringFromField(yachtNumberOfGuests),
        has_kitchen: hasKitchen,
        has_alarm_system: hasAlarmSystem,
        has_garden: hasGarden,
        has_terrace: hasTerrace,
        has_balcony: hasBalcony,
        has_elevator: hasElevator,
        has_cellar: hasCellar,
        has_airconditioning: hasAirconditioning,
        has_patio: hasPatio,
        has_seaview: hasSeaview,
        has_tennis_yard: hasTennisYard,
        has_parking: hasParking,
        has_boxroom: hasBoxroom,
        has_pool: hasPool,
        has_guest_toilet: hasGuestToilet,
        is_waterfront: isWaterfront,
        is_elegant: isElegant,
        has_squash_court: hasSquashCourt,
        has_club_house: hasClubHouse,
        has_golf_course: hasGolfCourse,
        has_playground: hasPlayground,
        is_wheelchair_accessible: isWheelchairAccessible,
        has_builtin_wardrobe: hasBuiltinWardrobe,
        is_furnished: isFurnished,
        is_senior_oriented: isSeniorOriented,
        has_open_view: hasOpenView,
        has_green_view: hasGreenView,
        has_mountain_view: hasMountainView,
        has_jacuzzi: hasJacuzzi,
        has_sauna: hasSauna,
        has_gym: hasGym,
        has_attic: hasAttic,
        has_garage: hasGarage,
        has_lease_agreement: hasLeaseAgreement,
    },
});

export const getPropertyFilterContext: ContextGetter<{
    searchRadius?: string;
    country?: string;
    region?: string;
    city?: string;
    district?: string;
    postalCode?: string;
    neighborhood?: string;
    place?: string;
    licenseArea?: string;
    type?: PropertyTypesType;
    subType?: string;
    constructionYearMin?: string | number;
    constructionYearMax?: string | number;
    priceMin?: string | number;
    priceMax?: string | number;
    rentPriceMin?: string | number;
    rentPriceMax?: string | number;
    measurementSystem?: MeasurementSystemType;
    measurementUnit?: string;
    businessArea?: string;
    currency?: string;
    livingAreaMin?: string | number;
    livingAreaMax?: string | number;
    plotAreaMin?: string | number;
    plotAreaMax?: string | number;
    floor?: string | number;
    totalFloors?: string | number;
    roomsMin?: string | number;
    roomsMax?: string | number;
    bathroomsMin?: string | number;
    bathroomsMax?: string | number;
    bedroomsMin?: string | number;
    bedroomsMax?: string | number;
    propertyCondition?: string;
    yachtLengthMin?: string | number;
    yachtLengthMax?: string | number;
    yachtCategory?: string;
    hasKitchen?: boolean;
    hasAlarmSystem?: boolean;
    hasGarden?: boolean;
    hasTerrace?: boolean;
    hasBalcony?: boolean;
    hasElevator?: boolean;
    hasCellar?: boolean;
    hasAirconditioning?: boolean;
    hasPatio?: boolean;
    hasSeaview?: boolean;
    hasTennisYard?: boolean;
    hasParking?: boolean;
    hasBoxroom?: boolean;
    hasPool?: boolean;
    isWaterfront?: boolean;
    isElegant?: boolean;
    searchIntent?: string;
    schemaVersion?: string;
}> = ({
    searchRadius,
    country,
    region,
    city,
    district,
    postalCode,
    neighborhood,
    place,
    licenseArea,
    type,
    subType,
    constructionYearMin,
    constructionYearMax,
    priceMin,
    priceMax,
    measurementSystem,
    measurementUnit,
    businessArea,
    currency,
    livingAreaMin,
    livingAreaMax,
    plotAreaMin,
    plotAreaMax,
    floor,
    totalFloors,
    roomsMin,
    roomsMax,
    bathroomsMin,
    bathroomsMax,
    bedroomsMin,
    bedroomsMax,
    propertyCondition,
    rentPriceMin,
    rentPriceMax,
    yachtLengthMin,
    yachtLengthMax,
    yachtCategory,
    hasKitchen,
    hasAlarmSystem,
    hasGarden,
    hasTerrace,
    hasBalcony,
    hasElevator,
    hasCellar,
    hasAirconditioning,
    hasPatio,
    hasSeaview,
    hasTennisYard,
    hasParking,
    hasBoxroom,
    hasPool,
    isWaterfront,
    isElegant,
    searchIntent,
    schemaVersion = '1-0-5',
}) => ({
    schema: `iglu:com.engelvoelkers/search_filter_context/jsonschema/${schemaVersion}`,
    data: {
        search_radius: getStringFromField(searchRadius),
        country: getStringFromField(country),
        region: getStringFromField(region),
        city: getStringFromField(city),
        district: getStringFromField(district),
        postal_code: getStringFromField(postalCode),
        neighborhood: getStringFromField(neighborhood),
        place: getStringFromField(place),
        license_area: getStringFromField(licenseArea),
        property_type: getStringFromField(type),
        property_sub_type: getStringFromField(subType),
        year_of_construction_min: getStringFromField(constructionYearMin),
        year_of_construction_max: getStringFromField(constructionYearMax),
        price_min: getStringFromField(priceMin),
        price_max: getStringFromField(priceMax),
        rent_price_min: getStringFromField(rentPriceMin),
        rent_price_max: getStringFromField(rentPriceMax),
        measurement_system: getStringFromField(measurementSystem),
        measurement_unit: getStringFromField(measurementUnit),
        business_area: getStringFromField(businessArea),
        currency: getStringFromField(currency),
        living_area_min: getStringFromField(livingAreaMin),
        living_area_max: getStringFromField(livingAreaMax),
        plot_area_min: getStringFromField(plotAreaMin),
        plot_area_max: getStringFromField(plotAreaMax),
        floor: getStringFromField(floor),
        total_floors: getStringFromField(totalFloors),
        rooms_min: getStringFromField(roomsMin),
        rooms_max: getStringFromField(roomsMax),
        bathrooms_min: getStringFromField(bathroomsMin),
        bathrooms_max: getStringFromField(bathroomsMax),
        bedrooms_min: getStringFromField(bedroomsMin),
        bedrooms_max: getStringFromField(bedroomsMax),
        property_condition: getStringFromField(propertyCondition),
        yacht_length_min: getStringFromField(yachtLengthMin),
        yacht_length_max: getStringFromField(yachtLengthMax),
        yacht_category: getStringFromField(yachtCategory),
        has_kitchen: hasKitchen,
        has_alarm_system: hasAlarmSystem,
        has_garden: hasGarden,
        has_terrace: hasTerrace,
        has_balcony: hasBalcony,
        has_elevator: hasElevator,
        has_cellar: hasCellar,
        has_airconditioning: hasAirconditioning,
        has_patio: hasPatio,
        has_seaview: hasSeaview,
        has_tennis_yard: hasTennisYard,
        has_parking: hasParking,
        has_boxroom: hasBoxroom,
        has_pool: hasPool,
        is_waterfront: isWaterfront,
        is_elegant: isElegant,
        search_intent: getStringFromField(searchIntent),
    },
});

export const getLocationContext: ContextGetter<{
    street?: string;
    houseNumber?: string | number;
    zipCode?: string | number;
    countryCode?: string;
    country?: string;
    region?: string;
    district?: string;
    licenseArea?: string;
    city?: string;
    lat?: string | number;
    lng?: string | number;
    placeId?: string;
    schemaVersion?: string;
}> = ({
    street,
    houseNumber,
    zipCode,
    countryCode,
    country,
    region,
    district,
    licenseArea,
    city,
    lat,
    lng,
    placeId,
    schemaVersion = '1-0-2',
}) => ({
    schema: `iglu:com.engelvoelkers/location_context/jsonschema/${schemaVersion}`,
    data: {
        street_name: getStringFromField(street),
        house_number: getStringFromField(houseNumber),
        postal_code: getStringFromField(zipCode),
        country_code: getStringFromField(countryCode),
        country: getStringFromField(country),
        region: getStringFromField(region),
        district: getStringFromField(district),
        license_area: getStringFromField(licenseArea),
        city: getStringFromField(city),
        latitude: getStringFromField(lat),
        longitude: getStringFromField(lng),
        place_id: getStringFromField(placeId),
    },
});

export const getABTestContext: ContextGetter<{
    id: string;
    name: string;
    variant: string;
    variant_description: string;
    schemaVersion?: string;
}> = ({ id, name, variant, variant_description, schemaVersion = '2-0-0' }) => ({
    schema: `iglu:com.engelvoelkers/ab_tests_context/jsonschema/${schemaVersion}`,
    data: {
        id,
        name,
        variant,
        variant_description,
    },
});

export const getUserInteractionContext: ContextGetter<{
    attribute: string;
    value: number;
    schemaVersion?: string;
}> = ({ attribute, value, schemaVersion = '1-0-0' }) => ({
    schema: `iglu:com.engelvoelkers/user_interaction_context/jsonschema/${schemaVersion}`,
    data: {
        attribute,
        value,
    },
});

export const getReferenceContext: ContextGetter<{
    sp_event_id: string;
    go3_uuid?: string;
    schemaVersion?: string;
}> = ({ sp_event_id, go3_uuid, schemaVersion = '1-0-1' }) => ({
    schema: `iglu:com.engelvoelkers/reference_context/jsonschema/${schemaVersion}`,
    data: {
        sp_event_id,
        go3_uuid,
    },
});

export const getShopContext: ContextGetter<{
    /**
     * @deprecated use `shop_id` instead
     */
    id?: string;
    name?: string;
    /**
     * @deprecated use `license_id` instead
     */
    licence_area_id?: string;
    organizational_unit_id?: string;
    organizational_unit_path?: string;
    utool_id?: string;
    info_desk_id?: string;
    is_open?: boolean;
    reviews_score?: string;
    google_maps_place_id?: string;
    google_maps_custom_image?: boolean;
    agent_id?: string;
    business_division_name?: string;
    shop_id?: string;
    license_id?: string;
    office_id?: string;
    schemaVersion?: string;
}> = ({
    id,
    name,
    licence_area_id,
    organizational_unit_id,
    organizational_unit_path,
    utool_id,
    info_desk_id,
    is_open,
    reviews_score,
    google_maps_place_id,
    google_maps_custom_image,
    agent_id,
    business_division_name,
    shop_id,
    license_id,
    office_id,
    schemaVersion = '1-0-8',
}) => ({
    schema: `iglu:com.engelvoelkers/shop_context/jsonschema/${schemaVersion}`,
    data: {
        id,
        name,
        licence_area_id,
        organizational_unit_id,
        organizational_unit_path,
        utool_id,
        info_desk_id,
        is_open,
        reviews_score,
        google_maps_place_id,
        google_maps_custom_image,
        agent_id,
        business_division_name,
        shop_id,
        license_id,
        office_id,
    },
});

export const getLicenseContext: ContextGetter<{
    id?: string;
    name?: string;
    schemaVersion?: string;
}> = ({ name, id, schemaVersion = '1-0-0' }) => ({
    schema: `iglu:com.engelvoelkers/license_context/jsonschema/${schemaVersion}`,
    data: {
        id,
        name,
    },
});

export const getSubscriptionContext: ContextGetter<{
    id?: string;
    reason?: string;
    schemaVersion?: string;
}> = ({ id, reason, schemaVersion = '1-0-0' }) => ({
    schema: `iglu:com.engelvoelkers/subscription_context/jsonschema/${schemaVersion}`,
    data: {
        id,
        reason,
    },
});

export const getConsentDocumentContext: ContextGetter<{
    vendors_consent?: string;
    vendors_consent_denied?: string;
    schemaVersion?: string;
}> = ({
    vendors_consent,
    vendors_consent_denied,
    schemaVersion = '1-0-0',
}) => ({
    schema: `iglu:com.engelvoelkers/consent_document_context/jsonschema/${schemaVersion}`,
    data: {
        vendors_consent,
        vendors_consent_denied,
    },
});

export const getSearchContext: ContextGetter<{
    search_term_typed?: string;
    search_term_selected?: string;
    category?: string;
    place_id?: string;
    latitude?: string;
    longitude?: string;
    country?: string;
    country_code?: string;
    region?: string;
    city?: string;
    district?: string;
    postal_code?: string;
    place?: string;
    use_my_location?: boolean;
    selected_term_position?: number;
    total_search_terms?: number;
    schemaVersion?: string;
}> = ({
    search_term_typed,
    search_term_selected,
    category,
    place_id,
    latitude,
    longitude,
    country,
    country_code,
    region,
    city,
    district,
    postal_code,
    place,
    use_my_location,
    selected_term_position,
    total_search_terms,
    schemaVersion = '1-0-1',
}) => ({
    schema: `iglu:com.engelvoelkers/search_context/jsonschema/${schemaVersion}`,
    data: {
        search_term_typed,
        search_term_selected,
        category,
        place_id,
        latitude,
        longitude,
        country,
        country_code,
        region,
        city,
        district,
        postal_code,
        place,
        use_my_location,
        selected_term_position,
        total_search_terms,
    },
});

export const getNavigationContext: ContextGetter<{
    target_url?: string;
    element_id?: string;
    selected_option?: string;
    target_screen?: string;
    source_screen?: string;
    schemaVersion?: string;
}> = ({
    target_url,
    element_id,
    selected_option,
    target_screen,
    source_screen,
    schemaVersion = '1-0-0',
}) => ({
    schema: `iglu:com.engelvoelkers/navigation_context/jsonschema/${schemaVersion}`,
    data: {
        target_url,
        element_id,
        selected_option,
        target_screen,
        source_screen,
    },
});

export const getApplicantContext: ContextGetter<{
    lever_application_id: string;
    schemaVersion?: string;
}> = ({ lever_application_id, schemaVersion = '1-0-0' }) => ({
    schema: `iglu:com.engelvoelkers/applicant_context/jsonschema/${schemaVersion}`,
    data: {
        lever_application_id,
    },
});
